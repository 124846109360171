@import '../src/variables.scss';
@import '../../../sass/main.scss';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

/* latin-ext */
@font-face {
    font-family: 'FontContentLight';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLDz8Z1JlFd2JQEl8qw.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'FontContentLight';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLDz8Z1xlFd2JQEk.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-family: 'FontSubHeading';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'FontSubHeading';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-family: 'FontHeading';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLCz7Z1JlFd2JQEl8qw.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'FontHeading';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    outline: none;
    text-decoration: none;
}

// generic css
@mixin flexbrowser($type, $box, $typebox) {
    display: -webkit-#{$box};
    display: -moz-#{$box};
    display: -webkit-#{$type};
    display: -ms-#{$typebox};
    display: $type;
}

.flex {
    @include flexbrowser(flex, box, flexbox);
}

.inline-flex {
    @include flexbrowser(inline-flex, inline-box, inline-flexbox);
}

.text__center {
    text-align: center;
}

.text__right {
    text-align: right;
}

.text--success {
    color: $color-success;
}

.text--danger {
    color: $color-danger;
}

.text--warning {
    color: $color-warning;
}

.cb {
    clear: both;
}

.header__sub {
    height: 60px;
    border-bottom: solid 0.5px #979797;
    background-color: #ffffff;
    position: fixed;
    width: 100%;
    left: 0;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px;
    z-index: 1;
}

.btn {
    cursor: pointer;
}

.btn__action {
    padding: 8px 16px;
    border: none;
    min-width: 100px;
    color: #fff;
    border-radius: 4px;
    text-align: center;
    font-size: 14px;
    font-family: 'FontContentLight';
    font-weight: 600;
}

.btn--success {
    background-color: #00e096;
}

.btn--danger {
    background-color: #ff3d71;
}

.btn--info {
    background-color: #3366ff;
}

.btn--disabled {
    background-color: grey;
}

.btn__controls {
    border: none;
    border-radius: 4px;
    text-align: center;
    font-family: 'FontContentLight';
    font-weight: 600;
    margin-left: 10px;
    padding: 6px 12px;
    min-width: 80px;
    font-size: 12px;
    box-shadow: inset 0 -1px 0 0 #c4c9cc;
}

.btn__refresh {
    padding: 5px 10px;
    border-radius: 4px;
    border: solid 1.2px #000;
    margin: 0px 10px;
    background: transparent;
}

.msg__err {
    font-size: 12px;
    color: rgb(216, 84, 89);
    font-family: 'FontSubHeading';
}

// search bar
.input__search {
    width: 330px;
    justify-content: space-between;
    align-items: center;
    padding: 13px 10px;
    border-radius: 4px;
    border: solid 1.2px #000;

    input {
        border: none;
        width: calc(100% - 20px);
    }

    input:focus {
        outline: none;
    }

    app-icon {
        display: flex;
    }
}

.line {
    border-bottom: 1px solid #e2e4e6;
    margin: 4px 0px;
}

.zero_state_content {
    font-family: 'FontContentLight';
    font-size: 14px;
    padding: 10px;
}

.inner_heading {
    font-family: 'FontSubHeading';
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.08px;
}

// switcher
.switch {
    position: relative;
    display: inline-block;
    width: 51px;
    height: 25px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d6edfe;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: 1px solid #580D7E;
}

.slider:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.switch input:checked+.slider {
    background-color: #580D7E;
}

.switch input:focus+.slider {
    box-shadow: 0 0 1px #580D7E;
}

.switch input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.headline__subhead {
    font-family: 'FontHeading';
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: -0.2px;
    color: #333333;
}

// pagination
.ngx-pagination {
    font-family: 'FontSubHeading';
    margin-bottom: 0px !important;
}

.ngx-pagination .disabled {
    font-size: 14px;
}

.ngx-pagination a {
    font-size: 14px;
    color: #868686;
}

.ngx-pagination .current {
    background: transparent !important;
    color: #580D7E !important;
    font-size: 14px;
    font-weight: bold;
}

.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-previous a::before {
    color: #363636;
}

.tag {
    color: #fff;
    padding: 7px 14px;
    border-radius: 4px;
    font-size: 9px;
    letter-spacing: 0.05px;
    text-align: center;
    font-family: 'FontSubHeading';
}

.tag--blue {
    background-color: #32c5ff;
}

.tag--yellow {
    background-color: #f7b500;
}

.tag--green {
    background-color: #6dd400;
}

.distance_frm_outlet {
    color: #707a80;
    margin-top: 5px;
}

.url__track {
    text-decoration: underline;
    color: #0091ff;
}

.card_heading {
    font-family: 'FontSubHeading';
    font-size: 24px;
    line-height: 1.33;
    margin-top: 1px;
}

.avoid-clicks {
    pointer-events: none;
}

.has_error {
    color: red;
    margin: 5px 0px;
    font-family: 'FontContentLight';
    letter-spacing: 0.08px;
    font-weight: 500;
    font-size: 13px;
    line-height: 1.3;

    a {
        color: #0091ff;
    }
}

.space__between__h {
    justify-content: space-between;
}

.align__center {
    align-items: center;
}

// success and failure popup
.direction {
    flex-direction: column;
    align-items: center;
}

.heading_failure {
    font-size: 17px;
    font-family: 'FontSubHeading';
    font-weight: 900;
    letter-spacing: 1px;
    line-height: 40px;
}

.notification__error {
    font-size: 15px;
    letter-spacing: 1px;
    font-family: 'FontContentLight';
    line-height: 20px;
}

#cancelOrder {
    label {
        display: block;
        margin: 10px 0px;
        cursor: pointer;
    }

    div.text__right {
        margin-top: 30px;
    }
}

/* tooltip css */

.tooltip {
    position: relative;
}

.tooltip .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.tooltip .tooltip-text {
    min-width: 120px;
    max-width: 120px;
    padding: 10px 20px;
    color: #fff;
    background-color: #000;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    font-family: inherit;
    border-radius: 8px;
    position: absolute;
    z-index: 99999999;
    box-sizing: border-box;
    border: 1px solid #000;
    visibility: hidden;
}

.tooltip:hover .tooltip-text {
    visibility: visible;
}

.tooltip .tooltip-text i {
    position: absolute;
    overflow: hidden;
}

.tooltip .tooltip-text i::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #000;
    border: 1px solid #000;
}

/* left tooltip */

.left {
    top: 50%;
    right: 100%;
    margin-right: 20px;
    transform: translate(0, -50%);
}

.tooltip .tooltip-text.left i {
    top: 50%;
    left: 100%;
    margin-top: -12px;
    width: 12px;
    height: 24px;
}

.tooltip .tooltip-text.left i::after {
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
}

/* bottom tooltip */

.bottom {
    top: 30px;
    left: 50%;
    transform: translate(-50%, 0);
}

.tooltip .tooltip-text.bottom i {
    bottom: 100%;
    left: 50%;
    margin-left: -12px;
    width: 24px;
    height: 12px;
}

.tooltip .tooltip-text.bottom i::after {
    left: 50%;
    transform: translate(-50%, 50%) rotate(45deg);
}

/* right tooltip */

.right {
    top: 50%;
    left: 100%;
    margin-left: 20px;
    transform: translate(0, -50%);
}

.tooltip .tooltip-text.right i {
    top: 50%;
    right: 100%;
    margin-top: -12px;
    width: 12px;
    height: 24px;
}

.tooltip .tooltip-text.right i::after {
    left: 0;
    top: 50%;
    transform: translate(50%, -50%) rotate(-45deg);
}

/* top tooltip */

.top {
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, 0);
}

.tooltip .tooltip-text.top i {
    top: 100%;
    left: 50%;
    margin-left: -12px;
    width: 24px;
    height: 12px;
}

.tooltip .tooltip-text.top i::after {
    left: 50%;
    transform: translate(-50%, -50%) rotate(135deg);
}

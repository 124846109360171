@use '../abstracts/colors' as c;
@use '../abstracts/type' as t;
@use '../helpers/variables' as v;

.btn-thrive_primary {
    position: relative;
    padding: 10px 20px !important;
    border: 1px solid map-get(c.$colors, 'text', '400') !important;
    border-radius: v.$border-radius !important;
    max-height: 45px;
    height: 45px !important;
    cursor: pointer;
    background: map-get(c.$colors, 'tertiary', '100') !important;
    color: map-get(c.$colors, 'text', '400') !important;
    text-decoration: none;
    font-size: map-get(t.$font, 'size', '300') !important;
    font-weight: map-get(t.$font, 'weight', 'bold') !important;
    box-shadow: 2px 2px 0px 0px #000000;
    white-space: nowrap;
    text-transform: uppercase;

    &:hover {
        background: map-get(c.$colors, 'tertiary', '300') !important;
    }

    &.saving {
        padding-left: 45px !important;
        pointer-events: none;

        &::before {
            content: '\ea5b';
            font-family: 'Material Icons';
            position: absolute;
            top: 10px;
            transform-origin: center center;
            left: 15px;
            font-size: 18px;
            width: 18px;
            height: 24px;
            animation: spin;
            animation-delay: 1s, 1s;
            animation-duration: 1s;
            animation-iteration-count: infinite;
        }
    }

    &:disabled,
    &[disabled] {
        opacity: 0.3;
    }

    .mat-icon {
        font-size: 22px !important;
        min-width: 22px;
        height: 22px !important;
    }
}

.btn-thrive_secondary {
    padding: 10px 20px !important;
    max-height: 45px;
    height: 45px !important;
    cursor: pointer;
    background: transparent;
    border-radius: v.$border-radius !important;
    border: 1px solid map-get(c.$colors, 'primary', '500') !important;
    font-size: map-get(t.$font, 'size', '300') !important;
    font-weight: map-get(t.$font, 'weight', 'bold') !important;
    background: transparent !important;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-transform: uppercase;
    color: map-get(c.$colors, 'primary', '500') !important;
    --mdc-text-button-label-text-color: map-get(c.$colors, 'primary', '500') !important;
    text-transform: uppercase;

    &.mat-mdc-button.mat-unthemed {
        --mdc-text-button-label-text-color: map-get(c.$colors, 'primary', '500') !important;
    }

    mat-icon,
    .mdc-button__label {
        color: map-get(c.$colors, 'primary', '500') !important;
    }

    .mat-icon {
        font-size: 18px;
        min-width: 18px;
        height: 18px;
        color: map-get(c.$colors, 'primary', '500') !important;
    }

    &:hover {
        box-shadow: 2px 2px 0px 0px map-get(c.$colors, 'primary', '500');
    }

    &:disabled,
    &[disabled] {
        opacity: 0.3;
    }

    &.no-outline {
        border: 1px solid transparent !important;
    }
}

.btn-thrive_primary,
.btn-thrive_secondary {
    &.saving {
        padding-left: 45px !important;
        pointer-events: none;
        opacity: 0.5;

        &::before {
            content: '\ea5b';
            font-family: 'Material Icons';
            position: absolute;
            top: 11px;
            transform-origin: center center;
            left: 15px;
            font-size: 18px;
            width: 18px;
            height: 24px;
            animation: spin;
            animation-delay: 1s, 1s;
            animation-duration: 1s;
            animation-iteration-count: infinite;
        }
    }

    .mdc-button__label {
        margin-top: 3px;
    }
}

.btn-thrive_icon {
    max-height: 45px;
    height: 45px !important;
    width: 45px !important;
    min-width: 45px !important;
    cursor: pointer;
    border-radius: v.$border-radius !important;
    border: 1px solid transparent !important;
    background: transparent !important;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    position: relative;

    &.bordered {
        border: 1px solid map-get(c.$colors, 'primary', '500') !important;

        &:hover {
            border: 1px solid map-get(c.$colors, 'primary', '500') !important;
            background: map-get(c.$colors, 'primary', '100') !important;
            box-shadow: 2px 2px 0px 0px map-get(c.$colors, 'primary', '500');
        }
    }

    &:disabled {
        .mat-icon {
            color: #c9c9c9 !important;
        }
    }

    .mat-icon {
        font-size: 24px;
        min-width: 24px;
        height: 24px;
        margin-right: 0;
        color: map-get(c.$colors, 'primary', '500') !important;
    }

    &.delete-btn {
        border: 1px solid map-get(c.$colors, 'failure', '200') !important;
        background: map-get(c.$colors, 'failure', '100') !important;

        .mat-icon {
            color: map-get(c.$colors, 'failure', '200') !important;
        }

        &:hover {
            background: map-get(c.$colors, 'failure', '200') !important;
            border: 1px solid map-get(c.$colors, 'failure', '200') !important;

            .mat-icon {
                color: map-get(c.$colors, 'text', '100') !important;
            }
        }
    }

    &:hover {
        background: map-get(c.$colors, 'primary', '100') !important;
        border: transparent !important;

        .mat-icon {
            color: map-get(c.$colors, 'text', '500') !important;
        }
    }
}

.btn_thrive-secondry {
    padding: 10px 20px !important;
    max-height: 35px;
    height: 35px !important;
    cursor: pointer;
    border-radius: v.$border-radius !important;
    border: 2px solid map-get(c.$colors, 'primary', '500') !important;
    font-size: map-get(t.$font, 'size', '300') !important;
    font-weight: map-get(t.$font, 'weight', 'bold') !important;
    background: #fff !important;
    text-decoration: none;
    display: flex !important;
    align-items: center;
    justify-content: center;
    position: relative;
    color: map-get(c.$colors, 'primary', '500');

    .mat-icon {
        font-size: 18px;
        min-width: 18px;
        height: 18px;
        color: map-get(c.$colors, 'primary', '500') !important;
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}
